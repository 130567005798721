<template>
    <v-app style="background-color: #191B20;">
        <v-container class="my-3 mx-xs-0">
            <v-row>
                <v-img
                class="my-4"
                lazy-src="https://picsum.photos/id/11/10/6"
                max-height="64"
                max-width="100"
                src="@/assets/images/logo1.svg"
                ></v-img>
            </v-row>
            <v-row class="mt-12">
                <p class="text-h4 font-weight-bold yellow--text text--darken-3">เข้าสู่ระบบ</p>
            </v-row>
            <v-row>
                <p class="text-subtitle-1 white--text">ระบบจัดการสินค้าอย่างมีประสิทธิภาพ</p>
            </v-row>
            <v-row>
                <v-form ref="loginForm" class="w-400px" v-model="isValid">
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        label="ชื่อผู้ใช้งาน"
                        outlined
                        dense
                        required
                        v-model="username"
                        :rules="usernameRules"
                        @keydown.enter="submitLogin"
                    ></v-text-field>
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        label="รหัสผู้ใช้งาน"
                        type="password"
                        outlined
                        dense
                        required
                        v-model="password"
                        :rules="passwordRules"
                        @keydown.enter="submitLogin"
                    ></v-text-field>
                    <v-row>
                        <v-col cols="8">
                            <v-checkbox
                            dark
                            color="yellow darken-3"
                            class="mt-0"
                            label="จดจำรหัสผู้ใช้งานของฉันในระบบ"
                            required
                            v-model="isRememberMe"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-btn text
                            color="yellow darken-3"
                            @click="$router.push({ name: 'ForgotPassword' })"
                            >
                            ลืมรหัสผ่าน ?
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="yellow darken-3" class="w-400px" elevation="2" @click="submitLogin">เข้าสู่ระบบ</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-row>
            <alert-error :text="errorMessage" ref="alertError"></alert-error>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios';
import AlertError from '../components/AlertError.vue';

export default {
    data: () => ({
        username: '',
        password: '',
        isRememberMe: false,
        isValid: false,
        usernameRules: [
            v => !!v || 'โปรดกรอกข้อมูลชื่อผู้ใช้งาน'
        ],
        passwordRules: [
            v => !!v || 'โปรดกรอกข้อมูลรหัสผ่าน'
        ],
        errorMessage: ''
    }),
    methods: {
        async submitLogin(){
            this.$refs.loginForm.validate();
            if(this.isValid){
                this.errorMessage = '';
                const body = {
                    username: this.username,
                    password: this.password
                };
                let loader = this.$loading.show();
                try{
                    const response = await axios.post(process.env.VUE_APP_BASE_URL + '/login', body);
                    const token = response.data.token;
                    localStorage.setItem('jwt', token);
                    if(this.isRememberMe){
                        const refreshToken = response.data.refreshToken;
                        localStorage.setItem('refreshToken', refreshToken);
                        localStorage.setItem('username', this.username);
                    }
                    this.$router.replace({ name: 'Home' });
                }catch(error){
                    this.errorMessage = error.response?.data || error.message;
                }finally{
                    loader.hide();
                }
                
            }
        }
    },
    watch: {
        errorMessage(){
            if(this.errorMessage != ''){
                this.$refs.alertError.show();
            }
        }
    },
    components: {
        AlertError
    },
    async mounted(){
    }
};
</script>

<style scoped>
    .w-400px{
        width: 400px;
    }
</style>